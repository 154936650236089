<template>
	<v-container fluid class="pa-0">
		<v-toolbar elevation="1" :style="stickyTop">
			<v-toolbar-title>Coachees</v-toolbar-title>
			<v-btn icon @click="fetchCoachees()"
				><v-icon>mdi-refresh</v-icon></v-btn
			>
			<v-spacer />
			<!-- add COACHEE -->
			<v-btn fab icon @click="openDialogAdd">
				<v-icon> mdi-plus-circle-outline </v-icon>
			</v-btn>
			<v-dialog width="800" max-width="100vw" v-model="showDialogAdd">
				<AddCoachee
					:key="dialogKey"
					v-on:closeDialogAdd="showDialogAdd = false"
					v-on:refreshList="fetchCoachees"
				/>
			</v-dialog>
			<v-spacer />
		</v-toolbar>
		<v-container>
			<v-data-table
				:items="coachees"
				:headers="headers"
				:disable-sort="this.$vuetify.breakpoint.mobile"
				:loading="loading"
				group-by="coach.name"
				item-key="uid"
				disable-pagination
				hide-default-footer
				no-data-text=""
				no-results-text=""
			>
				<template v-slot:[`item.actions`]="{ item }">
					<v-toolbar flat>
						<v-toolbar-items>
							<UpdateCoachee :coachee="item"></UpdateCoachee>
							<v-btn
								icon
								v-show="!item.userId"
								@click="deleteAccount(item.id)"
								><v-icon>mdi-delete</v-icon></v-btn
							>
							<v-btn
								v-show="!item.userId"
								text
								plain
								@click="createAccount(item)"
								>create account</v-btn
							>
							<v-btn
								v-show="item.userId && !item.emailSent"
								text
								plain
								@click="sendEmail(item)"
								>uitnodigen
							</v-btn>
						</v-toolbar-items>
					</v-toolbar>
				</template>
			</v-data-table>
		</v-container>
	</v-container>
</template>

<script>
	import AddCoachee from "@/components/AddCoachee";
	import UpdateCoachee from "@/components/UpdateCoachee";
	import { db, functions } from "@/services/firebase";
	import { httpsCallable } from "firebase/functions";
	import {
		doc,
		deleteDoc,
		collection,
		getDocs,
		query,
		where,
	} from "firebase/firestore";
	import { mapGetters } from "vuex";
	export default {
		name: "Coachees",
		components: { AddCoachee, UpdateCoachee },
		data() {
			return {
				loading: false,
				coachees: [],
				tableHeight: null,
				dialogKey: 1,
				searchText: "",
				showDialogAdd: false,
				showDialogUpdate: false,
			};
		},
		computed: {
			...mapGetters({
				userInfo: "session/userInfo",
			}),
			headers() {
				return [
					{ text: "Naam", value: "name.lastName" },
					{ text: "Voornaam", value: "name.firstName" },
					{ text: "Email", value: "email" },
					{ text: "Coach", value: "coach.name" },
					{ text: "Acties", value: "actions" },
				];
			},
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + "px",
					zIndex: 1,
				};
			},
		},
		methods: {
			async fetchCoachees() {
				switch (this.userInfo.role) {
					case "admin":
						const cRef = collection(db, "coachees");
						const res = await getDocs(cRef);
						this.coachees = res.docs.map((doc) => {
							return { ...doc.data(), id: doc.id };
						});
						break;

					case "coach":
						const q = query(
							collection(db, "coachees"),
							where("coach.id", "==", this.userInfo.coach.id)
						);
						const _coachees = await getDocs(q);
						this.coachees = _coachees.docs.map((doc) => {
							return {
								...doc.data(),
								id: doc.id,
							};
						});
						break;

					case "coachee":
						// TBD
						break;

					default:
						this.coachees = [];
				}
			},
			openDialogAdd() {
				this.dialogKey += 1;
				this.showDialogAdd = true;
			},
			async createAccount(_coachee) {
				this.loading = true;
				try {
					const params = {
						email: _coachee.email,
						displayName:
							_coachee.name.firstName + " " + _coachee.name.lastName,
						role: "coachee",
						id: _coachee.id,
					};
					const call = httpsCallable(functions, "createUser");
					const result = await call(params);
					_coachee.userId = result.data.userId;
				} catch (e) {
					this.$store.dispatch("session/showBottomMessage", e);
				}
				this.loading = false;
			},
			async deleteAccount(_id) {
				try {
					await deleteDoc(doc(db, "coachees", _id));
					this.fetchCoachees();
				} catch (e) {
					this.$store.dispatch("session/showBottomMessage", e);
				}
			},
			async sendEmail(_coachee) {
				this.loading = true;
				try {
					const params = {
						to_uid: _coachee.userId,
						role: "coachee",
						id: _coachee.id,
					};
					const call = httpsCallable(functions, "sendEmail");
					await call(params);
					_coachee.emailSent = true;
				} catch (e) {
					console.error(e);
				}
				this.loading = false;
			},
		},
		created() {
			this.fetchCoachees();
		},
	};
</script>

