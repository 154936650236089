<template>
	<v-card>
		<v-card-title>Coachee toevoegen</v-card-title>
		<v-card-text>
			<v-text-field
				v-model="coachee.name.firstName"
				label="Voornaam"
			></v-text-field>
			<v-text-field
				v-model="coachee.name.lastName"
				label="Achternaam"
			></v-text-field>
			<v-text-field v-model="coachee.email" label="Email"></v-text-field>
			<v-autocomplete
				v-model="coachee.coach"
				:items="coachesList"
				item-text="name"
				item-value="id"
				label="Coach"
				return-object
			></v-autocomplete>
		</v-card-text>
		<v-card-actions>
			<v-btn plain :loading="loading" @click.stop="addCoachee"
				>Bevestigen</v-btn
			>
			<v-spacer />
			<v-btn plain @click="$emit('closeDialogAdd')">Annuleren</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
	import { db } from "@/services/firebase";
	import { collection, addDoc, getDocs } from "firebase/firestore";
	import { mapGetters } from "vuex";
	export default {
		name: "addCoachee",
		props: {},
		data() {
			return {
				loading: false,
				coachee: {
					name: {
						firstName: "",
						lastName: "",
					},
					email: "",
					coach: {},
				},
				coachesList: [],
			};
		},
		computed: {
			...mapGetters({
				userInfo: "session/userInfo",
			}),
		},
		methods: {
			async addCoachee() {
				this.loading = true;
				this.coachee.email = this.coachee.email.trim();
				try {
					await addDoc(collection(db, "coachees"), this.coachee);
					this.$emit("refreshList");
					this.$emit("closeDialogAdd");
				} catch (err) {
					this.$store.dispatch("session/showBottomMessage", err);
				}
				this.loading = false;
			},
			async fetchCoaches() {
				switch (this.userInfo.role) {
					case "admin":
						const _collection = collection(db, "coaches");
						const _coaches = await getDocs(_collection);
						this.coachesList = _coaches.docs.map((doc) => {
							return {
								id: doc.id,
								name:
									doc.data().name.firstName +
									" " +
									doc.data().name.lastName,
							};
						});
						break;

					case "coach":
						this.coachesList = [];
						this.coachesList.push(this.userInfo.coach);
						this.coachee.coach = this.userInfo.coach;
						break;

					default:
						this.coachesList = [];
				}
			},
		},
		created() {
			this.fetchCoaches();
		},
	};
</script>
