<template>
	<v-dialog width="800" max-width="100vw" v-model="dialog">
		<template v-slot:activator="{ on, attrs }">
			<v-btn icon v-bind="attrs" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title>Coachee wijzigen</v-card-title>
			<v-card-text>
				<v-text-field
					v-model="coachee.name.firstName"
					label="Voornaam"
				></v-text-field>
				<v-text-field
					v-model="coachee.name.lastName"
					label="Achternaam"
				></v-text-field>
				<v-text-field
					v-model="coachee.email"
					label="Email"
					:readonly="!!coachee.userId"
				></v-text-field>
			</v-card-text>
			<v-card-actions>
				<v-btn plain :loading="loading" @click.stop="updateCoachee"
					>Bevestigen</v-btn
				>
				<v-spacer />
				<v-btn plain @click="dialog = false">Annuleren</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
	import { db } from "@/services/firebase";
	import { doc, collection, updateDoc } from "firebase/firestore";
	import { mapGetters } from "vuex";
	export default {
		name: "UpdateCoachee",
		props: {
			coachee: Object,
		},
		data() {
			return {
				dialog: false,
				loading: false,
			};
		},
		computed: {
			...mapGetters({
				userInfo: "session/userInfo",
			}),
		},
		methods: {
			async updateCoachee() {
				this.loading = true;
				this.coachee.email = this.coachee.email.trim();
				try {
					const docRef = doc(db, "coachees", this.coachee.id);
					await updateDoc(docRef, {
						name: {
							firstName: this.coachee.name.firstName,
							lastName: this.coachee.name.lastName,
						},
						email: this.coachee.email,
					});
					this.dialog = false;
				} catch (err) {
					this.$store.dispatch("session/showBottomMessage", err);
				}
				this.loading = false;
			},
		},
	};
</script>
